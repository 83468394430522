export var SearchList = [
  { labe: '地址名称', code: 'addressName', type: 'input' },
  { labe: '地质属性', code: 'addressProperty', type: 'input' }
]
export var SearchData = {
  addressName: '',
  addressProperty: ''
}
export var tableField = [
  { label: '编码', code: 'baseCode', type: 'input', width: '' },
  { label: '基地名称', code: 'baseName', type: 'input', width: '' },
  { label: '地址', code: 'baseAddr', type: 'input', width: '' },
  { label: '联系人', code: 'baseHead', type: 'input', width: '' },
  { label: '联系电话', code: 'phone', type: 'input', width: '' },
  { label: '备注', code: 'remark', type: 'input', width: '' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: function (index, row) {
      return row.status === 0 ? '正常' : row.status === 1 ? '停用' : row.status
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [{
      actionLabel: '编辑',
      id: '10'
    }/* , {
      actionLabel: '启用',
      id: '11'
    }, {
      actionLabel: '停用',
      id: '12'
    } */]
  }
]
